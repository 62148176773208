import router from 'next/router';
import Cookies from 'universal-cookie';
import flags from 'lib/flags';
import errors from 'lib/errors';
import parseUrl from 'lib/parse-url';
import pathsAreEqual from 'lib/paths-are-equal';
import mobileScreenSizes from 'lib/data/mobile-screen-sizes';
import * as analytics from 'lib/analytics';
import {
    getAnnotationsIdsInSidebarDisplayOrder,
    getCanvasAssetsToDisplay,
    getLatestRevisionForSelectedCanvas
} from '../selectors';
import {
    FEEDBACK_LINK_ANNOTATION_EDIT,
    FEEDBACK_LINK_ANNOTATION_SCREENSHOT_SET,
    FEEDBACK_LINK_ANNOTATION_SELECT,
    FEEDBACK_LINK_BATCH_ACTIONS_STATE_TOGGLE,
    FEEDBACK_LINK_BATCH_ACTION_ANNOTATION_IDS_SET,
    FEEDBACK_LINK_BATCH_ACTIONS_SET,
    FEEDBACK_LINK_CANVAS_ASSET_SELECT,
    FEEDBACK_LINK_CANVAS_SELECT,
    FEEDBACK_LINK_COMMENTING_DISABLE,
    FEEDBACK_LINK_COMMENTING_ENABLE,
    FEEDBACK_LINK_COMMENTS_BY_PAGE_SORT,
    FEEDBACK_LINK_COMMENTS_BY_RECENCY_SORT,
    FEEDBACK_LINK_COMMENTS_STATUS_SHOW,
    FEEDBACK_LINK_DESIGN_PINS_VISIBILITY_TOGGLE,
    FEEDBACK_LINK_DESIGN_ZOOM_AUTO_DISABLE,
    FEEDBACK_LINK_DESIGN_ZOOM_FIT_SET,
    FEEDBACK_LINK_DESIGN_ZOOM_PERCENTAGE_SET,
    FEEDBACK_LINK_DEVICE_TYPE_SET,
    FEEDBACK_LINK_EXPORT_SERVICE_SELECT,
    FEEDBACK_LINK_FILTERED_ANNOTATIONS_SET,
    FEEDBACK_LINK_FILTERS_SET,
    FEEDBACK_LINK_MARK_USER_AS_PRESENT,
    FEEDBACK_LINK_MODAL_EXPORT_ANNOTATIONS_HIDE,
    FEEDBACK_LINK_MODAL_EXPORT_ANNOTATIONS_SHOW,
    FEEDBACK_LINK_MODAL_FILE_ATTACHMENT_HIDE,
    FEEDBACK_LINK_MODAL_FILE_ATTACHMENT_SHOW,
    FEEDBACK_LINK_MODAL_GUEST_LOGIN_HIDE,
    FEEDBACK_LINK_MODAL_GUEST_LOGIN_SHOW,
    FEEDBACK_LINK_MODAL_INTRO_HIDE,
    FEEDBACK_LINK_MODAL_INTRO_SHOW,
    FEEDBACK_LINK_MODAL_NEW_ANNOTATION_HIDE,
    FEEDBACK_LINK_MODAL_NEW_ANNOTATION_SHOW,
    FEEDBACK_LINK_MODAL_NEW_REVISION_HIDE,
    FEEDBACK_LINK_MODAL_NEW_REVISION_SHOW,
    FEEDBACK_LINK_MODAL_REVIEW_CANVAS_HIDE,
    FEEDBACK_LINK_MODAL_REVIEW_CANVAS_SHOW,
    FEEDBACK_LINK_NEW_ANNOTATION_COMMENT_SET,
    FEEDBACK_LINK_NEW_ANNOTATION_DATA_UPDATE,
    FEEDBACK_LINK_NEW_ANNOTATION_MODE_SET,
    FEEDBACK_LINK_PAGE_NAVIGATE_START,
    FEEDBACK_LINK_PAGE_NAVIGATE_STOP,
    FEEDBACK_LINK_PAGE_NAVIGATE,
    FEEDBACK_LINK_PATH_SET,
    FEEDBACK_LINK_PRODUCT_TOUR_START,
    FEEDBACK_LINK_REPLY_EDIT,
    FEEDBACK_LINK_REVISION_SELECT,
    FEEDBACK_LINK_SEARCH_ACTIVE_TOGGLE,
    FEEDBACK_LINK_SEARCH_TERM_SET,
    FEEDBACK_LINK_SIDEBAR_CLOSE,
    FEEDBACK_LINK_SIDEBAR_OPEN,
    FEEDBACK_LINK_USER_AGENT_SET,
    FEEDBACK_LINK_VIEW_MODE_SET,
    MODAL_SHARE_CANVAS_HIDE,
    MODAL_SHARE_CANVAS_SHOW
} from './types';

export function selectFeedbackLinkCanvas(canvasId) {
    return {
        type: FEEDBACK_LINK_CANVAS_SELECT,
        payload: { canvasId }
    };
}

export function selectFeedbackLinkAnnotation(annotationId, navigate = true) {
    return (dispatch, getState) => {
        const state = getState();
        const {
            annotations,
            auth: { user },
            canvases,
            canvasRevisions,
            ui
        } = state;

        const canvas = canvases[ui.feedbackLink.canvasId];
        const annotation = annotations[annotationId];
        const latestRevision = getLatestRevisionForSelectedCanvas(state);
        const selectedRevision = canvasRevisions[ui.feedbackLink.revisionId];
        const showRevisionInUrl = latestRevision && selectedRevision.id !== latestRevision.id;

        // Don't allow non-authorized users to view private comments, and properly throw an error if annotation isn't found.
        if (
            (annotationId && !annotation) ||
            (annotation && annotation.isPrivate && !flags.userHasPrivateCommentsEnabled(user, canvas))
        ) {
            throw errors.NotFound();
        }

        if (navigate && typeof window !== 'undefined') {
            const path = window.location.hash ? window.location.hash.slice(1) : '';
            const url = annotationId
                ? `/link?subdomain=${canvas.subdomain}&annotationId=${annotationId}`
                : `/link?subdomain=${canvas.subdomain}${
                      showRevisionInUrl ? `&version=${selectedRevision.number}` : ''
                  }`;
            const asUrl = annotationId
                ? `/link/${canvas.subdomain}/comment/${annotationId}/`
                : `/link/${canvas.subdomain}/${showRevisionInUrl ? `v/${selectedRevision.number}` : ''}/`;
            // Note: We don't update hash state when canvas is in an iframe
            router.replace(url, asUrl + (window === window.parent && path ? `#${path}` : ''), { shallow: true });
        }

        if (annotationId) {
            if (!ui.feedbackLink.commentingEnabled) {
                dispatch(enableFeedbackLinkCommenting());
            }

            if (['design', 'email'].includes(canvas.type) && annotation.CanvasAssetId) {
                dispatch(selectFeedbackLinkCanvasAsset(annotation.CanvasAssetId, annotation.canvasAssetPageIndex));
            }

            if (
                canvas.type === 'website' &&
                !pathsAreEqual(annotation.path, ui.feedbackLink.path) &&
                !annotation.inactiveReason
            ) {
                dispatch(startFeedbackLinkPageNavigating());
            }

            // Resize window to closest appropriate size (not on actual mobile devices)
            if (['website', 'email'].includes(canvas.type) && ui.feedbackLink.deviceType !== 'mobile') {
                const { viewMode } = ui.feedbackLink;
                const annotationLeftOnMobile =
                    annotation.metadata &&
                    annotation.metadata.window &&
                    annotation.metadata.window.height > annotation.metadata.window.width &&
                    annotation.metadata.window.width <= 768;

                // If annotation was left on mobile,
                // resize the screen to the closest mobile size we have
                if (annotationLeftOnMobile) {
                    let closestWidth = mobileScreenSizes[0].width;
                    mobileScreenSizes.forEach((size) => {
                        if (annotation.metadata.window.width >= size.width) {
                            closestWidth = size.width;
                        }
                    });
                    dispatch(setFeedbackLinkViewModeMobile(closestWidth));
                } else if (viewMode !== 'desktop') {
                    dispatch(setFeedbackLinkViewModeDesktop());
                }
            }
        }

        // Calculate previous and next annotation ids for sidebar
        let previousAnnotationId = null;
        let nextAnnotationId = null;
        let currentIndex = null;
        let numAnnotationsInList = null;
        if (annotationId) {
            const annotationIdsInSidebarDisplayOrder = getAnnotationsIdsInSidebarDisplayOrder(state);

            currentIndex = annotationIdsInSidebarDisplayOrder.indexOf(annotationId);
            numAnnotationsInList = annotationIdsInSidebarDisplayOrder.length;

            previousAnnotationId =
                currentIndex === annotationIdsInSidebarDisplayOrder.length - 1
                    ? annotationIdsInSidebarDisplayOrder[0]
                    : annotationIdsInSidebarDisplayOrder[currentIndex + 1];

            nextAnnotationId =
                currentIndex === 0
                    ? annotationIdsInSidebarDisplayOrder[annotationIdsInSidebarDisplayOrder.length - 1]
                    : annotationIdsInSidebarDisplayOrder[currentIndex - 1];
        }

        return dispatch({
            type: FEEDBACK_LINK_ANNOTATION_SELECT,
            payload: {
                annotationId,
                previousAnnotationId,
                nextAnnotationId,
                currentIndex,
                numAnnotationsInList
            }
        });
    };
}

export function selectPreviousFeedbackLinkAnnotation(shortcutUsed = false) {
    return (dispatch, getState) => {
        const {
            canvases,
            ui: {
                feedbackLink: { canvasId, selectedAnnotationMetadata }
            }
        } = getState();

        if (!selectedAnnotationMetadata || !selectedAnnotationMetadata.previousAnnotationId) {
            return;
        }

        const canvas = canvases[canvasId];
        analytics.navigateSidebarComment(canvas, 'previous', shortcutUsed);

        dispatch(selectFeedbackLinkAnnotation(selectedAnnotationMetadata.previousAnnotationId));
    };
}

export function selectNextFeedbackLinkAnnotation(shortcutUsed = false) {
    return (dispatch, getState) => {
        const {
            canvases,
            ui: {
                feedbackLink: { canvasId, selectedAnnotationMetadata }
            }
        } = getState();

        if (!selectedAnnotationMetadata || !selectedAnnotationMetadata.nextAnnotationId) {
            return;
        }

        const canvas = canvases[canvasId];
        analytics.navigateSidebarComment(canvas, 'next', shortcutUsed);

        dispatch(selectFeedbackLinkAnnotation(selectedAnnotationMetadata.nextAnnotationId));
    };
}

export function selectFeedbackLinkCanvasAsset(
    canvasAssetId,
    canvasAssetPageIndex = null,
    shortcutUsed = false,
    track = true
) {
    return (dispatch, getState) => {
        // Short-circuit the clearing of canvas asset
        if (!canvasAssetId) {
            dispatch({
                type: FEEDBACK_LINK_CANVAS_ASSET_SELECT,
                payload: { canvasAssetId, canvasAssetPageIndex }
            });
            return;
        }

        const state = getState();
        const { canvasAssets, canvases, ui } = state;
        const canvasAsset = canvasAssets[canvasAssetId];
        const canvas = canvasAsset ? canvases[canvasAsset.CanvasId] : null;
        const allCanvasAssetIds = getCanvasAssetsToDisplay(state).map((canvasAsset) => canvasAsset.id);
        const path = canvasAssetPageIndex ? canvasAssetPageIndex + 1 : allCanvasAssetIds.indexOf(canvasAsset.id) + 1;

        // Note: We don't update hash state when canvas is in an iframe
        if (
            typeof window !== 'undefined' &&
            window.history &&
            window.history.replaceState &&
            window === window.parent
        ) {
            window.history.replaceState(null, null, canvasAsset.type === 'email' ? '' : `#${path}`);
        } else if (typeof window !== 'undefined' && window.location && window === window.parent) {
            window.location.hash = canvasAsset.type === 'email' ? '' : `#${path}`;
        }

        if (typeof window !== 'undefined' && track) {
            const previousCanvasAsset = canvasAssets[ui.feedbackLink.canvasAssetId];
            const previousCanvasAssetPageIndex = ui.feedbackLink.canvasAssetPageIndex;
            analytics.selectCanvasAsset(
                canvas,
                canvasAsset,
                previousCanvasAsset,
                canvasAssetPageIndex,
                previousCanvasAssetPageIndex,
                shortcutUsed
            );
        }

        dispatch({
            type: FEEDBACK_LINK_CANVAS_ASSET_SELECT,
            payload: { canvasAssetId, canvasAssetPageIndex }
        });
    };
}
export function selectFeedbackLinkRevision(revisionId, track = true, navigate = true) {
    return (dispatch, getState) => {
        const state = getState();
        const { canvases, canvasRevisions, canvasAssets, ui } = state;
        const canvas = canvases[ui.feedbackLink.canvasId];
        const selectedRevision = canvasRevisions[revisionId];
        const annotationId = ui.feedbackLink.selectedAnnotationId;
        const previousCanvasRevision = canvasRevisions[revisionId];
        const latestRevision = getLatestRevisionForSelectedCanvas(state);
        const showRevisionInUrl = latestRevision && selectedRevision.id !== latestRevision.id;

        if (typeof window !== 'undefined' && track && previousCanvasRevision !== selectedRevision) {
            analytics.selectCanvasRevision(canvas, selectedRevision, selectedRevision);
        }

        if (!annotationId && navigate && typeof window !== 'undefined') {
            const path = window.location.hash ? window.location.hash.slice(1) : '';
            const url = `/link?subdomain=${canvas.subdomain}${
                showRevisionInUrl ? `&version=${selectedRevision.number}` : ''
            }`;
            const asUrl = `/link/${canvas.subdomain}/${showRevisionInUrl ? `v/${selectedRevision.number}` : ''}/`;
            // Note: We don't update hash state when canvas is in an iframe
            router.replace(url, asUrl + (window === window.parent && canvas.type !== 'email' ? `#${path}` : ''), {
                shallow: true
            });
        }

        dispatch({
            type: FEEDBACK_LINK_REVISION_SELECT,
            payload: { revisionId }
        });

        if (['email', 'design'].includes(canvas.type) && typeof window !== 'undefined') {
            const activeCanvasAssets = canvas.CanvasAssets.map((id) => canvasAssets[id]).sort(
                (a, b) => a.position - b.position
            );
            const canvasAssetToSelect = activeCanvasAssets.find(
                (canvasAsset) => canvasAsset.CanvasRevisionId === revisionId
            );
            const canvasAssetPageIndex = canvasAssetToSelect && canvasAssetToSelect.type === 'pdf' ? 0 : null;

            if (canvasAssetToSelect) {
                dispatch(selectFeedbackLinkCanvasAsset(canvasAssetToSelect.id, canvasAssetPageIndex, false, false));
            } else {
                dispatch(selectFeedbackLinkCanvasAsset(null, null, false, false));
            }
        }
    };
}

export function enableFeedbackLinkCommenting(shortcutUsed = false) {
    return (dispatch, getState) => {
        if (typeof window !== 'undefined') {
            const {
                canvases,
                ui: { feedbackLink }
            } = getState();
            const canvas = canvases[feedbackLink.canvasId];
            analytics.toggleBrowseComment(canvas, shortcutUsed);
        }
        return dispatch({
            type: FEEDBACK_LINK_COMMENTING_ENABLE,
            payload: null
        });
    };
}

export function disableFeedbackLinkCommenting(shortcutUsed = false) {
    return (dispatch, getState) => {
        const {
            canvases,
            ui: {
                feedbackLink: { isNewAnnotationModalOpen, canvasId }
            }
        } = getState();

        if (typeof window !== 'undefined') {
            const canvas = canvases[canvasId];
            analytics.toggleBrowseComment(canvas, shortcutUsed);
        }

        // Close new annotation modal if it's open
        if (isNewAnnotationModalOpen) {
            dispatch(hideFeedbackLinkNewAnnotationModal());
        }

        return dispatch({
            type: FEEDBACK_LINK_COMMENTING_DISABLE,
            payload: null
        });
    };
}

export function openFeedbackLinkSidebar() {
    return {
        type: FEEDBACK_LINK_SIDEBAR_OPEN,
        payload: null
    };
}

export function closeFeedbackLinkSidebar() {
    return {
        type: FEEDBACK_LINK_SIDEBAR_CLOSE,
        payload: null
    };
}

export function setFeedbackLinkCommentsStatusToShow(statuses) {
    return {
        type: FEEDBACK_LINK_COMMENTS_STATUS_SHOW,
        payload: { statuses }
    };
}

export function sortFeedbackLinkCommentsByRecency() {
    return {
        type: FEEDBACK_LINK_COMMENTS_BY_RECENCY_SORT,
        payload: null
    };
}

export function sortFeedbackLinkCommentsByPage() {
    return {
        type: FEEDBACK_LINK_COMMENTS_BY_PAGE_SORT,
        payload: null
    };
}

export function editFeedbackLinkAnnotation(annotationId) {
    return {
        type: FEEDBACK_LINK_ANNOTATION_EDIT,
        payload: { annotationId }
    };
}

export function editFeedbackLinkReply(replyId) {
    return {
        type: FEEDBACK_LINK_REPLY_EDIT,
        payload: { replyId }
    };
}

export function setFeedbackLinkPath(path, fullUrl = null, title = null) {
    return (dispatch, getState) => {
        const { canvases, ui } = getState();
        const canvas = canvases[ui.feedbackLink.canvasId];
        let updatedHash = path;

        // Use the fullUrl if the origin is different from the original canvas url
        if (canvas && fullUrl) {
            const parsedFullUrl = parseUrl(fullUrl);
            if (parsedFullUrl.origin !== canvas.localUrl) {
                updatedHash = fullUrl;
            }
        }

        // Note: we don't manipulate the hash state if canvas is in an iframe
        // or if its not a website canvas
        if (canvas.type === 'website' && typeof window !== 'undefined' && window === window.parent) {
            if (window.history && window.history.replaceState) {
                window.history.replaceState(null, null, `#${updatedHash}`);
            } else if (window.location && window === window.parent) {
                window.location.hash = `#${updatedHash}`;
            }
        }

        // If Pastel is within an iframe, we communicate page changes to the outside
        if (typeof window !== 'undefined' && window !== window.parent) {
            window.parent.postMessage(
                { type: 'path.change', data: { path, fullUrl, title }, isPastelMessage: true },
                '*'
            );
        }

        return dispatch({
            type: FEEDBACK_LINK_PATH_SET,
            payload: { path, fullUrl }
        });
    };
}

export function showFeedbackLinkIntroModal() {
    return {
        type: FEEDBACK_LINK_MODAL_INTRO_SHOW,
        payload: null
    };
}

export function hideFeedbackLinkIntroModal() {
    return {
        type: FEEDBACK_LINK_MODAL_INTRO_HIDE,
        payload: null
    };
}

export function showFeedbackLinkGuestLoginModal() {
    return {
        type: FEEDBACK_LINK_MODAL_GUEST_LOGIN_SHOW,
        payload: null
    };
}

export function hideFeedbackLinkGuestLoginModal() {
    return {
        type: FEEDBACK_LINK_MODAL_GUEST_LOGIN_HIDE,
        payload: null
    };
}

export function showFeedbackLinkNewAnnotationModal(data = {}) {
    return {
        type: FEEDBACK_LINK_MODAL_NEW_ANNOTATION_SHOW,
        payload: { data }
    };
}

export function updateFeedbackLinkNewAnnotationData(data = {}) {
    return {
        type: FEEDBACK_LINK_NEW_ANNOTATION_DATA_UPDATE,
        payload: { data }
    };
}

export function hideFeedbackLinkNewAnnotationModal() {
    return {
        type: FEEDBACK_LINK_MODAL_NEW_ANNOTATION_HIDE,
        payload: null
    };
}

export function setFeedbackLinkNewAnnotationModeAsTextEditing() {
    return {
        type: FEEDBACK_LINK_NEW_ANNOTATION_MODE_SET,
        payload: { mode: 'text-edit' }
    };
}

export function clearFeedbackLinkNewAnnotationMode() {
    return {
        type: FEEDBACK_LINK_NEW_ANNOTATION_MODE_SET,
        payload: { mode: null }
    };
}

export function setFeedbackLinkNewAnnotationComment(comment) {
    return {
        type: FEEDBACK_LINK_NEW_ANNOTATION_COMMENT_SET,
        payload: { comment }
    };
}

export function showFeedbackLinkExportAnnotationsModal() {
    return {
        type: FEEDBACK_LINK_MODAL_EXPORT_ANNOTATIONS_SHOW,
        payload: null
    };
}

export function hideFeedbackLinkExportAnnotationsModal() {
    return {
        type: FEEDBACK_LINK_MODAL_EXPORT_ANNOTATIONS_HIDE,
        payload: null
    };
}

export function showFeedbackLinkNewRevisionModal() {
    return {
        type: FEEDBACK_LINK_MODAL_NEW_REVISION_SHOW,
        payload: null
    };
}

export function hideFeedbackLinkNewRevisionModal() {
    return {
        type: FEEDBACK_LINK_MODAL_NEW_REVISION_HIDE,
        payload: null
    };
}

export function toggleFeedbackLinkBatchActions(batchActionsState) {
    return {
        type: FEEDBACK_LINK_BATCH_ACTIONS_STATE_TOGGLE,
        payload: { batchActionsState }
    };
}

export function setFeedbackLinkBatchActionAnnotationIds(annotationIds) {
    return {
        type: FEEDBACK_LINK_BATCH_ACTION_ANNOTATION_IDS_SET,
        payload: { annotationIds }
    };
}

export function showFeedbackLinkAnnotationScreenshot(annotationId) {
    return (dispatch, getState) => {
        const { annotations, canvases } = getState();
        const annotation = annotations[annotationId];
        const canvas = canvases[annotation?.CanvasId];
        analytics.viewCommentScreenshot(annotation, canvas);
        dispatch({
            type: FEEDBACK_LINK_ANNOTATION_SCREENSHOT_SET,
            payload: { annotationId }
        });
    };
}

export function showFeedbackLinkFileAttachmentModal(fileAttachmentId) {
    return {
        type: FEEDBACK_LINK_MODAL_FILE_ATTACHMENT_SHOW,
        payload: { fileAttachmentId }
    };
}

export function hideFeedbackLinkFileAttachmentModal() {
    return {
        type: FEEDBACK_LINK_MODAL_FILE_ATTACHMENT_HIDE,
        payload: null
    };
}

export function selectFeedbackLinkExportServiceTrello() {
    return {
        type: FEEDBACK_LINK_EXPORT_SERVICE_SELECT,
        payload: { service: 'trello' }
    };
}

export function selectFeedbackLinkExportServiceMonday() {
    return {
        type: FEEDBACK_LINK_EXPORT_SERVICE_SELECT,
        payload: { service: 'monday' }
    };
}

export function selectFeedbackLinkExportServiceAsana() {
    return {
        type: FEEDBACK_LINK_EXPORT_SERVICE_SELECT,
        payload: { service: 'asana' }
    };
}

export function selectFeedbackLinkExportServiceJira() {
    return {
        type: FEEDBACK_LINK_EXPORT_SERVICE_SELECT,
        payload: { service: 'jira' }
    };
}

export function selectFeedbackLinkExportServiceZapier() {
    return {
        type: FEEDBACK_LINK_EXPORT_SERVICE_SELECT,
        payload: { service: 'zapier' }
    };
}

export function selectFeedbackLinkExportServiceWebhook() {
    return {
        type: FEEDBACK_LINK_EXPORT_SERVICE_SELECT,
        payload: { service: 'webhook' }
    };
}

export function selectFeedbackLinkExportServiceCSV() {
    return {
        type: FEEDBACK_LINK_EXPORT_SERVICE_SELECT,
        payload: { service: 'csv' }
    };
}

export function selectFeedbackLinkExportServiceClickup() {
    return {
        type: FEEDBACK_LINK_EXPORT_SERVICE_SELECT,
        payload: { service: 'clickup' }
    };
}

export function clearFeedbackLinkExportService() {
    return {
        type: FEEDBACK_LINK_EXPORT_SERVICE_SELECT,
        payload: { service: null }
    };
}

export function hideFeedbackLinkAnnotationScreenshot() {
    return {
        type: FEEDBACK_LINK_ANNOTATION_SCREENSHOT_SET,
        payload: { annotationId: null }
    };
}

export function markFeedbackLinkUserAsPresent({ userId, name, avatarUrl }) {
    return {
        type: FEEDBACK_LINK_MARK_USER_AS_PRESENT,
        payload: { userId, name, avatarUrl, timestamp: Date.now() }
    };
}

export function setFeedbackLinkUserAgent(userAgent) {
    return {
        type: FEEDBACK_LINK_USER_AGENT_SET,
        payload: { userAgent }
    };
}

export function setFilteredFeedbackLinkAnnotations(annotationIds) {
    return {
        type: FEEDBACK_LINK_FILTERED_ANNOTATIONS_SET,
        payload: { annotationIds }
    };
}

export function setFeedbackLinkSearch(search) {
    return {
        type: FEEDBACK_LINK_SEARCH_TERM_SET,
        payload: { search }
    };
}

export function setFeedbackLinkFilters(filters = {}) {
    return {
        type: FEEDBACK_LINK_FILTERS_SET,
        payload: { filters }
    };
}

export function setFeedbackLinkBatchActions(batchActions = {}) {
    return {
        type: FEEDBACK_LINK_BATCH_ACTIONS_SET,
        payload: { batchActions }
    };
}

export function setFeedbackLinkViewModeDesktop() {
    return (dispatch) => {
        if (typeof window !== 'undefined') {
            const cookies = new Cookies();
            const cookieOptions = { sameSite: 'none', secure: true };
            cookies.set('pastel.canvas.viewMode', 'desktop', cookieOptions);
        }

        return dispatch({
            type: FEEDBACK_LINK_VIEW_MODE_SET,
            payload: { viewMode: 'desktop' }
        });
    };
}

export function setFeedbackLinkViewModeMobile(viewWidth) {
    return (dispatch) => {
        if (typeof window !== 'undefined') {
            const cookies = new Cookies();
            const cookieOptions = { sameSite: 'none', secure: true };
            cookies.set('pastel.canvas.viewMode', 'mobile', cookieOptions);
        }

        return dispatch({
            type: FEEDBACK_LINK_VIEW_MODE_SET,
            payload: { viewMode: 'mobile', viewWidth }
        });
    };
}

export function setFeedbackLinkDeviceDesktop() {
    return {
        type: FEEDBACK_LINK_DEVICE_TYPE_SET,
        payload: { deviceType: 'desktop' }
    };
}

export function setFeedbackLinkDeviceMobile() {
    return {
        type: FEEDBACK_LINK_DEVICE_TYPE_SET,
        payload: { deviceType: 'mobile' }
    };
}

// This actually instructs the underlying iframe to navigate to the given url
export function setFeedbackLinkPageToNavigateTo(url) {
    return {
        type: FEEDBACK_LINK_PAGE_NAVIGATE,
        payload: { url }
    };
}

// This basically opens up a little info modal on the fact
// that the page is navigating
export function startFeedbackLinkPageNavigating() {
    return {
        type: FEEDBACK_LINK_PAGE_NAVIGATE_START,
        payload: null
    };
}

export function stopFeedbackLinkPageNavigating() {
    return {
        type: FEEDBACK_LINK_PAGE_NAVIGATE_STOP,
        payload: null
    };
}

export function startFeedbackLinkProductTour() {
    return {
        type: FEEDBACK_LINK_PRODUCT_TOUR_START,
        payload: null
    };
}

export function hideShareCanvasModal() {
    return {
        type: MODAL_SHARE_CANVAS_HIDE,
        payload: null
    };
}

export function showShareCanvasModal(canvasId) {
    return {
        type: MODAL_SHARE_CANVAS_SHOW,
        payload: { canvasId }
    };
}

export function hideFeedbackLinkReviewCanvasModal() {
    return {
        type: FEEDBACK_LINK_MODAL_REVIEW_CANVAS_HIDE,
        payload: null
    };
}

export function showFeedbackLinkReviewCanvasModal(canvasId) {
    return {
        type: FEEDBACK_LINK_MODAL_REVIEW_CANVAS_SHOW,
        payload: { canvasId }
    };
}

export function setDesignZoomPercentage(zoomPercentage) {
    return {
        type: FEEDBACK_LINK_DESIGN_ZOOM_PERCENTAGE_SET,
        payload: { zoomPercentage }
    };
}

export function setDesignFitWidth() {
    return {
        type: FEEDBACK_LINK_DESIGN_ZOOM_FIT_SET,
        payload: { zoomToFit: 'width' }
    };
}

export function setDesignFitHeight() {
    return {
        type: FEEDBACK_LINK_DESIGN_ZOOM_FIT_SET,
        payload: { zoomToFit: 'height' }
    };
}

export function clearDesignFit() {
    return {
        type: FEEDBACK_LINK_DESIGN_ZOOM_FIT_SET,
        payload: { zoomToFit: null }
    };
}

export function disableAutoZoom() {
    return {
        type: FEEDBACK_LINK_DESIGN_ZOOM_AUTO_DISABLE,
        payload: null
    };
}

export function toggleDesignPinsVisibility(visibilityState) {
    return {
        type: FEEDBACK_LINK_DESIGN_PINS_VISIBILITY_TOGGLE,
        payload: { visibilityState }
    };
}

export function toggleSearchActive(searchState) {
    return {
        type: FEEDBACK_LINK_SEARCH_ACTIVE_TOGGLE,
        payload: { searchState }
    };
}
