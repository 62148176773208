import { MODAL_CANVAS_SETTINGS_HIDE, MODAL_CANVAS_SETTINGS_SHOW } from './types';

export function showCanvasSettingsModal(canvasId) {
    return {
        type: MODAL_CANVAS_SETTINGS_SHOW,
        payload: { canvasId }
    };
}

export function hideCanvasSettingsModal() {
    return {
        type: MODAL_CANVAS_SETTINGS_HIDE,
        payload: null
    };
}
