/* eslint-disable no-underscore-dangle */
import mixpanel from 'mixpanel-browser';
import Cookies from 'universal-cookie';
import moment from 'moment';
import getFileExtension from 'lib/get-file-extension';
import parseMentions from 'lib/parse-mentions';

// -------------- USER/CUSTOMER ACCOUNT EVENTS -------------- //

/**
 * Identifies a user to Mixpanel analytics, and customer.io
 *
 * @export
 * @param {*} user
 * @param {null | 'signup' | 'login'} [type=null] Whether identify is being called at signup/login.
 * @return {*}
 */
export function identify(user, type = null) {
    if (typeof window === 'undefined' || !user) {
        return;
    }

    // Customer.io
    // Note: excluding guests from customer.io
    if (window._cio && !user.isGuest) {
        const [firstName, lastName] = (user.name || '').split(' ');
        window._cio.identify({
            id: user.id,
            name: user.name + (user.isGuest ? ' (guest)' : ''),
            first_name: firstName,
            last_name: lastName,
            email: user.email,
            created_at: Math.round(new Date(user.createdAt).getTime() / 1000),
            plan_id: user.Customer ? user.Customer.planId : null,
            team_name: user.Team ? user.Team.name : null,
            is_guest: user.isGuest,
            role: user.role,
            team_owner_user_id: user.Team ? user.Team.ownerUserId : null,
            subscription_status: user.Customer ? user.Customer.status : null
        });
    }

    if (window.Canny) {
        window.Canny('identify', {
            appID: '60a2e2aff3f8b535a40f8c2c',
            user: {
                email: user.email,
                name: user.name,
                id: user.id,
                avatarURL: user.avatarUrl,
                created: new Date(user.createdAt).toISOString()
            }
        });
    }

    if (window.profitwell && user.Customer) {
        window.profitwell('start', { user_id: user.Customer.customerId });
    }

    mixpanel.identify(user.id);

    if (user.isGuest) {
        return;
    }

    mixpanel.register({
        'Company plan name': user.Customer.planId,
        'Company plan status': user.Customer.status,
        'Team ID': user.Team?.id,
        Role: user.Team ? (user.Team.ownerUserId === user.id ? 'account owner' : user.role) : 'account owner'
    });

    const cookies = new Cookies();
    const userInfoWasSet = cookies.get('pastel.user-info-was-tracked');

    if (!userInfoWasSet || type === 'signup' || type === 'login') {
        mixpanel.people.set({
            $name: user.name,
            $email: user.email,
            $user_id: user.id,
            'Company plan name': user.Customer.planId,
            'Company plan status': user.Customer.status,
            'Team ID': user.Team?.id,
            Role: user.Team ? (user.Team.ownerUserId === user.id ? 'account owner' : user.role) : 'account owner',
            'Sign up date': new Date(user.createdAt).toISOString()
        });

        // Expires in an hour
        cookies.set('pastel.user-info-was-tracked', 'true', {
            maxAge: 60 * 60,
            secure: true,
            sameSite: 'none',
            path: '/'
        });
    }
}

/**
 * Identify user to Intercom.
 */
export function intercomIdentify(user) {
    if (typeof window === 'undefined') {
        return;
    }

    if (!window.Intercom) {
        return;
    }

    if (!window.commsBooted) {
        const bootData = {
            app_id: 'zxaivxcc'
        };

        if (user) {
            bootData.name = user.name + (user.isGuest ? ' (guest)' : '');
            bootData.email = user.email;
            bootData.created_at = Math.round(new Date(user.createdAt).getTime() / 1000);
            bootData.plan = user.Customer ? user.Customer.planId : null;
            bootData.teamName = user.Team ? user.Team.name : null;
        }

        window.Intercom('boot', bootData);
        window.commsBooted = true;
        return;
    }

    const updateData = user
        ? {
              name: user.name + (user.isGuest ? ' (guest)' : ''),
              email: user.email,
              createdAt: Math.round(new Date(user.createdAt).getTime() / 1000),
              plan: user.Customer ? user.Customer.planId : null,
              teamName: user.Team ? user.Team.name : null
          }
        : null;

    window.Intercom('update', updateData);
}

/**
 * User enters a URL and clicks create on demo page
 *
 * @export
 * @param {*} url
 */
export function useDemo(url) {
    mixpanel.track('Used demo', {
        'Demo url': url
    });
}

/**
 * User signs up for an account.
 *
 * @export
 */
export function signup() {
    if (typeof window.qp !== 'undefined') {
        window.qp('track', 'CompleteRegistration');
    }

    if (typeof window.gtag !== 'undefined') {
        window.gtag('event', 'conversion', { send_to: 'AW-932175094/f7SLCNbCiIcBEPa5v7wD' });
    }

    if (typeof window.fbq !== 'undefined') {
        window.fbq('track', 'CompleteRegistration');
    }

    // Note: a bunch more signup-related props are tracked in analytics.identify

    mixpanel.track('Sign up');
}

/**
 * User logs into their account.
 *
 * @export
 */
export function login() {
    // Note: a bunch more login-related props are tracked in analytics.identify
    mixpanel.track('Log in');
    if (window._cio) {
        window._cio.track('Log in');
    }
}

/**
 * A guest account is logged in
 *
 * @export
 * @param {*} user
 * @param {*} canvas
 */
export function guestLogin(user, canvas) {
    mixpanel.people.set({
        $name: user.name,
        $email: user.email,
        $user_id: user.id,
        Role: 'guest',
        'Canvas guest created on ID': canvas.id,
        'Team guest attributed to ID': canvas.User.TeamId,
        'User guest attributed to ID': canvas.UserId
    });
    mixpanel.track('Guest account login');
}

/**
 * User adds payment information.
 *
 * @export
 * @param {*} plan
 */
export function paymentInfoAdd(plan) {
    if (typeof window.qp !== 'undefined') {
        window.qp('track', 'AddPaymentInfo');
    }

    if (typeof window.gtag !== 'undefined') {
        window.gtag('event', 'conversion', {
            send_to: 'AW-932175094/JieZCLnViIcBEPa5v7wD',
            value: plan.amount,
            currency: 'USD'
        });
    }

    if (typeof window.fbq !== 'undefined') {
        window.fbq('track', 'AddPaymentInfo', { value: plan.amount, currency: 'USD' });
    }
}

/**
 * A user changes their plan
 *
 * @export
 * @param {*} oldPlanId
 * @param {*} newPlanId
 */
export function changePlan(oldPlanId, newPlanId) {
    mixpanel.track('Change plan', {
        'Old company plan name': oldPlanId,
        'New company plan name': newPlanId
    });
    mixpanel.people.set({
        'Company plan name': newPlanId
    });
}

/**
 * A user cancels their plan
 *
 * @export
 */
export function cancelPlan() {
    mixpanel.people.set({
        'Cancellation date': new Date().toISOString(),
        'Company plan status': 'cancelled'
    });
    mixpanel.track('Cancel plan');
}

/**
 * A user adds a credit card and processes their first payment
 *
 * @export
 * @param {*} user
 */
export function upgradePlan(planId) {
    mixpanel.people.set({
        'Company plan name': planId,
        'Company plan status': 'active',
        'Upgrade date': new Date().toISOString()
    });
    mixpanel.track('Plan upgrade', {
        'Plan upgraded to': planId
    });
}

/**
 * User clicks "Upgrade" button anywhere in the app
 *
 * @export
 * @param {*} location
 */
export function clickUpgradeButton(location) {
    mixpanel.track('Upgrade button clicked', {
        'Upgrade click location': location
    });
}

/**
 * User clicks Add a card to upgrade in billing page
 *
 * @export
 */
export function addCardClick() {
    mixpanel.track('Add card clicked');
}

/**
 * A user downloads a PDF invoice from the billing page
 *
 * @export
 */
export function downloadPDFInvoice() {
    mixpanel.track('Download PDF invoice');
}

/**
 * A user adds a coupon to their account
 *
 * @export
 * @param {*} couponId
 */
export function addCoupon(couponId) {
    mixpanel.track('Add coupon', {
        'Coupon ID': couponId
    });
}

/**
 * A team owner / admin invites a user to their account
 *
 * @export
 */
export function sendTeamInvite() {
    mixpanel.track('Team invite sent');
    if (window._cio) {
        window._cio.track('Team invite sent');
    }
}

/**
 * A user responds to a team invite
 *
 * @export
 * @param {boolean} [accepted=true]
 * @param {string} source
 */
export function teamInviteResponse(accepted = true, source) {
    const teamInviteResponseData = {
        'Invite response': accepted ? 'accepted' : 'declined',
        'Invite source': source
    };

    mixpanel.track('Team invite response', teamInviteResponseData);
    if (window._cio) {
        window._cio.track('Team invite response', teamInviteResponseData);
    }
}

/**
 * A user changes their name
 *
 * @export
 * @param {*} name
 * @param {*} previousName
 */
export function changeName(name, previousName) {
    mixpanel.track('Name changed', {
        'Previous name': previousName,
        'New name': name
    });
}

/**
 * A user changes their email
 *
 * @export
 * @param {*} email
 * @param {*} previousEmail
 */
export function changeEmail(email, previousEmail) {
    mixpanel.track('Email changed', {
        'Previous email': previousEmail,
        'New email': email
    });
}

/**
 * A user toggles their own notification settings
 *
 * @export
 * @param {boolean} [toggleOn=true]
 */
export function toggleUserEmailNotifications(toggleOn = true) {
    mixpanel.track('User email notifications toggle', {
        'Previous notification state': toggleOn ? 'off' : 'on',
        'New notification state': toggleOn ? 'on' : 'off'
    });
}

/**
 * A user toggles their client's notification settings
 *
 * @export
 * @param {boolean} [toggleOn=true]
 */
export function toggleClientEmailNotifications(toggleOn = true) {
    mixpanel.track('Client email notifications toggle', {
        'Previous notification state': toggleOn ? 'off' : 'on',
        'New notification state': toggleOn ? 'on' : 'off'
    });
}

/**
 * A user updates their password
 *
 * @export
 */
export function changePassword() {
    mixpanel.track('Password change');
}

/**
 * A user requests a password reset email
 *
 * @export
 */
export function requestPasswordReset() {
    mixpanel.track('Password reset request');
}

// -------------- VIEW & NOTIFICATION EVENTS -------------- //

/**
 * A user views an invoice from the billing page
 *
 * @export
 */
export function viewInvoice() {
    mixpanel.track('View invoice');
}

/**
 * View upcoming invoice
 *
 * @export
 */
export function viewUpcomingInvoice() {
    mixpanel.track('View upcoming invoice');
}

/**
 * Sends a page-view event to customer.io
 * @param user
 */
export function viewPage(user) {
    if (typeof window === 'undefined' || !user) {
        return;
    }

    // Note: excluding guests from customer.io
    if (window._cio && !user.isGuest) {
        window._cio.track('pageview_event');
    }

    mixpanel.track('Page view');
}

/**
 * Users views a canvas
 *
 * @export
 * @param {*} canvas
 */
export function viewCanvas(canvas) {
    const canvasViewData = {
        'Canvas owner email': canvas.User.email,
        'Canvas owner ID': canvas.User.id
    };

    mixpanel.track('Canvas view', canvasViewData);
    if (window._cio) {
        window._cio.track('Canvas view', canvasViewData);
    }
}

/**
 * A user views a folder
 *
 * @export
 * @param {*} folder
 */
export function viewFolder(folder) {
    mixpanel.track('Viewed folder', {
        'Folder ID': folder.id,
        'Folder name': folder.name,
        'Folder owner ID': folder.UserId,
        'Parent folder ID': folder.parentFolderId || null
    });
}

/**
 * A user views a screenshot
 *
 * @export
 * @param {*} annotation
 * @param {*} canvas
 */
export function viewCommentScreenshot(annotation, canvas) {
    mixpanel.track('View comment screenshot', {
        'Canvas ID': annotation.CanvasId,
        'Canvas owner ID': canvas.UserId,
        'Canvas owner team ID': canvas.User.TeamId,
        'Comment creator ID': annotation.UserId,
        'Comment ID': annotation.id
    });
}

/**
 * A user views notifications in the dashboard
 *
 * @export
 */
export function viewDashboardNotifications() {
    mixpanel.track('Dashboard notifications viewed');
}

/**
 * A user clears notifications
 *
 * @export
 * @param {*} location
 * @param {*} canvas
 */
export function clearNotifications(location, canvas = null) {
    mixpanel.track('Notifications cleared', {
        'Canvas ID': canvas?.id,
        'Canvas owner ID': canvas?.UserId,
        'Canvas owner team ID': canvas?.User.TeamId,
        'Notifications clear location': location
    });
}

/**
 * A user clicks on Show token in profile
 *
 * @export
 */
export function viewAPIToken() {
    mixpanel.track('Viewed API token');
}

// -------------- TOUR EVENTS -------------- //

/**
 * User starts dashboard tour
 *
 * @export
 * @param {*} stepIndex
 * @param {*} stepTarget
 */
export function startDashboardTour(stepIndex, stepTarget) {
    mixpanel.track('Dashboard tour started', {
        'Dashboard tour step index': stepIndex,
        'Dashboard tour step target': stepTarget
    });
}

/**
 * Users goes to next step in dashboard tour
 *
 * @export
 * @param {*} stepIndex
 * @param {*} stepTarget
 */
export function nextStepDashboardTour(stepIndex, stepTarget) {
    mixpanel.track('Dashboard tour next step', {
        'Dashboard tour step index': stepIndex,
        'Dashboard tour step target': stepTarget
    });
}

/**
 * User reaches end of dashboard tour
 *
 * @export
 * @param {*} stepIndex
 * @param {*} stepTarget
 */
export function endDashboardTour(stepIndex, stepTarget) {
    mixpanel.track('Dashboard tour ended', {
        'Dashboard tour step index': stepIndex,
        'Dashboard tour step target': stepTarget
    });
}

/**
 * Users dismisses dashboard tour early
 *
 * @export
 * @param {*} stepIndex
 * @param {*} stepTarget
 */
export function dismissDashboardTour(stepIndex, stepTarget) {
    mixpanel.track('Dashboard tour dismissed', {
        'Dashboard tour step index': stepIndex,
        'Dashboard tour step target': stepTarget
    });
}

/**
 * User is prompted to start a tour of the dashboard
 *
 * @export
 */
export function loadDashboardTourPrompt() {
    mixpanel.track('Dashboard tour prompt loaded');
}

/**
 * User presses button to start dashboard tour
 *
 * @export
 */
export function startDashboardTourPrompt() {
    mixpanel.track('Dashboard tour prompt started');
}

/**
 * User presses button to dismiss / not start dashboard tour
 *
 * @export
 */
export function dismissDashboardTourPrompt() {
    mixpanel.track('Dashboard tour prompt dismissed');
}

/**
 * Guest user starts canvas tour
 *
 * @export
 */
export function startGuestCanvasTour(stepIndex, stepTarget) {
    mixpanel.track('Guest canvas tour started', {
        'Guest canvas tour step index': stepIndex,
        'Guest canvas tour step target': stepTarget
    });
}

/**
 * Guest user reaches end of canvas tour
 *
 * @export
 * @param {*} stepIndex
 * @param {*} stepTarget
 */
export function endGuestCanvasTour(stepIndex, stepTarget) {
    mixpanel.track('Guest canvas tour ended', {
        'Guest canvas tour step index': stepIndex,
        'Guest canvas tour step target': stepTarget
    });
}

/**
 * Guest user prematurely dismisses canvas tour
 *
 * @export
 * @param {*} stepIndex
 * @param {*} stepTarget
 */
export function dismissGuestCanvasTour(stepIndex, stepTarget) {
    mixpanel.track('Guest canvas tour dismissed', {
        'Guest canvas tour step index': stepIndex,
        'Guest canvas tour step target': stepTarget
    });
}

/**
 * User starts canvas tour
 *
 * @export
 * @param {*} stepIndex
 * @param {*} stepTarget
 */
export function startCanvasTour(stepIndex, stepTarget) {
    mixpanel.track('Canvas tour started', {
        'Canvas tour step index': stepIndex,
        'Canvas tour step target': stepTarget
    });
}

/**
 * User goes to the next step of canvas tour
 *
 * @export
 * @param {*} stepIndex
 * @param {*} stepTarget
 */
export function nextStepCanvasTour(stepIndex, stepTarget) {
    mixpanel.track('Canvas tour next step', {
        'Canvas tour step index': stepIndex,
        'Canvas tour step target': stepTarget
    });
}

/**
 * User gets to the end of the canvas tour
 *
 * @export
 * @param {*} stepIndex
 * @param {*} stepTarget
 */
export function endCanvasTour(stepIndex, stepTarget) {
    mixpanel.track('Canvas tour ended', {
        'Canvas tour step index': stepIndex,
        'Canvas tour step target': stepTarget
    });
}

/**
 * User prematurely dismisses canvas tour
 *
 * @export
 * @param {*} stepIndex
 * @param {*} stepTarget
 */
export function dismissCanvasTour(stepIndex, stepTarget) {
    mixpanel.track('Canvas tour dismissed', {
        'Canvas tour step index': stepIndex,
        'Canvas tour step target': stepTarget
    });
}

// -------------- CANVAS EVENTS -------------- //

/**
 * A user creates a canvas
 *
 * @export
 * @param {*} canvas
 */
export function createCanvas(canvas) {
    const createCanvasData = {
        'Canvas type':
            canvas.type === 'design' ? (getFileExtension(canvas.title) === 'pdf' ? 'pdf' : canvas.type) : canvas.type,
        'Canvas URL': canvas.localUrl
    };

    mixpanel.track('Canvas created', createCanvasData);
    if (window._cio) {
        window._cio.track('Canvas created', createCanvasData);
    }
}

/**
 * A website canvas fails to be created
 *
 * @export
 * @param {string} message
 */
export function failedCanvas(message, url) {
    mixpanel.track('Canvas creation failed', {
        'Failed canvas URL': url,
        'Failure reason': message
    });
}

/**
 * A user deletes a canvas
 *
 * @export
 * @param {*} canvas
 */
export function deleteCanvas(canvas) {
    mixpanel.track('Canvas deleted', {
        'Canvas ID': canvas.id,
        'Canvas owner ID': canvas.UserId,
        'Canvas owner team ID': canvas.User.TeamId
    });
}

/**
 * A user archives a canvas
 *
 * @export
 * @param {*} canvas
 */
export function archiveCanvas(canvas) {
    mixpanel.track('Canvas archived', {
        'Canvas ID': canvas.id,
        'Canvas owner ID': canvas.UserId,
        'Canvas owner team ID': canvas.User.TeamId
    });
}

/**
 * A user unarchives a canvas
 *
 * @export
 * @param {*} canvas
 */
export function unarchiveCanvas(canvas) {
    mixpanel.track('Canvas unarchived', {
        'Canvas ID': canvas.id,
        'Canvas owner ID': canvas.UserId,
        'Canvas owner team ID': canvas.User.TeamId
    });
}

/**
 * A user renamed a canvas
 *
 * @export
 * @param {*} canvas
 * @param {*} name
 * @param {*} previousName
 * @param {*} method // form submit or editable field
 */
export function renameCanvas(canvas, name, previousName, method = 'form submit') {
    mixpanel.track('Canvas renamed', {
        'Canvas ID': canvas.id,
        'Canvas owner ID': canvas.UserId,
        'Canvas owner team ID': canvas.User.TeamId,
        'New canvas name': name,
        'Previous canvas name': previousName,
        Method: method
    });
}

/**
 * A user renamed a canvas
 *
 * @export
 * @param {*} canvas
 * @param {*} url
 * @param {*} previousUrl
 */
export function changeCanvasUrl(canvas, url, previousUrl) {
    mixpanel.track('Canvas changed url', {
        'Canvas ID': canvas.id,
        'Canvas owner ID': canvas.UserId,
        'Canvas owner team ID': canvas.User.TeamId,
        'New canvas url': url,
        'Previous canvas url': previousUrl
    });
}

/**
 * A user upgrades a free canvas to a premium one
 *
 * @export
 * @param {*} canvasId
 * @param {*} replacedCanvasId
 * @param {*} upgradeLocation
 * @param {*} numPremiumCanvases
 */
export function upgradeCanvas(canvasId, replacedCanvasId, upgradeLocation, numPremiumCanvases) {
    const upgradeCanvasData = {
        'Canvas ID': canvasId,
        'Canvas replaced ID': replacedCanvasId,
        'Canvas upgrade location': upgradeLocation,
        'Number of premium canvases': numPremiumCanvases
    };

    mixpanel.people.set({
        'Number of premium canvases': numPremiumCanvases
    });
    mixpanel.track('Canvas upgrade', upgradeCanvasData);
    if (window._cio) {
        window._cio.track('Canvas upgrade', upgradeCanvasData);
    }
}

/**
 * A user downgrades a premium canvas to a free one
 *
 * @export
 * @param {*} canvasId
 * @param {*} upgradeLocation
 * @param {*} replacedByCanvasId
 * @param {*} numPremiumCanvases
 */
export function downgradeCanvas(canvasId, replacedByCanvasId, upgradeLocation, numPremiumCanvases) {
    mixpanel.people.set({
        'Number of premium canvases': numPremiumCanvases
    });
    mixpanel.track('Canvas downgrade', {
        'Canvas ID': canvasId,
        'Canvas upgrade location': upgradeLocation,
        'Number of premium canvases': numPremiumCanvases,
        'Replaced by canvas ID': replacedByCanvasId
    });
}

/**
 * A user clicks the Share canvas button
 *
 * @export
 * @param {*} canvas
 * @param {*} location
 */
export function clickShareCanvasButton(canvas, location) {
    mixpanel.track('Share canvas button clicked', {
        'Canvas ID': canvas.id,
        'Canvas owner ID': canvas.UserId,
        'Canvas owner team ID': canvas.User.TeamId,
        'Share location': location
    });
}

/**
 * A user shares a canvas using email share or link copy
 *
 * @export
 * @param {*} canvas
 * @param {string} [method='email']
 * @param {*} numEmails
 * @param {*} customMessage
 * @param {*} location
 */
export function shareCanvas(canvas, method = 'email', numEmails, customMessage, location) {
    const shareCanvasData = {
        'Canvas ID': canvas.id,
        'Canvas owner ID': canvas.UserId,
        'Canvas owner team ID': canvas.User.TeamId,
        'Custom message': customMessage,
        'Number of emails shared with': numEmails,
        'Share location': location,
        'Share method': method
    };

    mixpanel.track('Canvas shared', shareCanvasData);
    if (window._cio) {
        window._cio.track('Canvas shared', shareCanvasData);
    }
}

/**
 * A user changes the folder (or sub-folder) visibility
 *
 * @export
 * @param {*} canvas
 * @param {*} newCanvasVisibility
 * @param {*} previousCanvasDescription
 */
export function changeCanvasVisibility(canvas, newCanvasVisibility, previousCanvasVisibility) {
    mixpanel.track('Canvas visibility changed', {
        'Canvas ID': canvas.id,
        'Canvas owner ID': canvas.UserId,
        'Canvas owner team ID': canvas.User.TeamId,
        'New canvas visibility': newCanvasVisibility,
        'Previous canvas visibility': previousCanvasVisibility
    });
}

/**
 * A user transfers canvas ownership to another team member
 *
 * @export
 * @param {*} canvas
 */
export function transferCanvasOwnership(canvas, newOwnerId) {
    mixpanel.track('Canvas ownership transferred', {
        'Canvas ID': canvas?.id,
        'Canvas owner ID': canvas?.UserId,
        'Canvas owner team ID': canvas?.User.TeamId,
        'New canvas owner ID': newOwnerId
    });
}

/**
 * A user clicks "See new activity" button on a canvas
 * @export
 * @param {*} canvas
 */
export function seeNewEventsOnCanvas(canvas) {
    mixpanel.track('See new activity button clicked', {
        'Canvas ID': canvas?.id,
        'Canvas owner ID': canvas?.UserId,
        'Canvas owner team ID': canvas?.User.TeamId
    });
}

/**
 * A user toggles between Browse and Comment
 *
 * @export
 * @param {*} canvas
 * @param {boolean} [shortcutUsed=false]
 */
export function toggleBrowseComment(canvas, shortcutUsed = false) {
    const toggleBrowseCommentData = {
        'Canvas ID': canvas.id,
        'Canvas owner ID': canvas.UserId,
        'Canvas owner team ID': canvas.User.TeamId,
        'Toggle shortcut used': shortcutUsed
    };

    mixpanel.track('Browse-Comment toggle', toggleBrowseCommentData);
    if (window._cio) {
        window._cio.track('Browse-Comment toggle', toggleBrowseCommentData);
    }
}

/**
 * A user creates a comment on a canvas
 *
 * @export
 * @param {*} annotation
 * @param {*} canvas
 * @param {*} user
 */
export function createComment(annotation, canvas) {
    const isMobile =
        annotation.metadata &&
        annotation.metadata.window &&
        annotation.metadata.window.height > annotation.metadata.window.width &&
        annotation.metadata.window.width < 500;
    const createCommentData = {
        'Canvas ID': annotation.CanvasId,
        'Canvas owner ID': canvas.UserId,
        'Canvas owner team ID': canvas.User.TeamId,
        'Comment ID': annotation.id,
        'Comment type': !annotation.textContentBefore ? 'text' : 'copy change',
        'Comment view': isMobile ? 'mobile' : 'desktop',
        'Comment visibility': annotation.isPrivate ? 'team' : 'everyone',
        'Files attached num': (annotation.FileAttachments || []).length,
        'Screen width': annotation.metadata?.window?.width,
        'Users mentioned IDs': parseMentions(annotation.comment).map((user) => user.id)
    };

    mixpanel.track('Comment created', createCommentData);
    if (window._cio) {
        window._cio.track('Comment created', createCommentData);
    }
}

/**
 * A user changes a comment status
 *
 * @export
 * @param {*} annotation
 * @param {*} canvas
 * @param {boolean} shortcutUsed
 * @param {string} oldStatus
 */
export function changeCommentStatus(annotation, canvas, shortcutUsed = false, oldStatus) {
    mixpanel.track('Comment status changed', {
        'Canvas ID': annotation.CanvasId,
        'Canvas owner ID': canvas.UserId,
        'Canvas owner team ID': canvas.User.TeamId,
        'Comment creator ID': annotation.UserId,
        'Comment ID': annotation.id,
        'Last exported': annotation.lastExportDate ? new Date(annotation.lastExportDate).toISOString() : undefined,
        'New status type': annotation.status,
        'Old status type': oldStatus,
        'Resolve shortcut used': shortcutUsed
    });
}

/**
 * A user edits a comment
 *
 * @export
 * @param {*} annotation
 * @param {*} canvas
 */
export function editComment(annotation, canvas) {
    mixpanel.track('Comment edited', {
        'Canvas ID': annotation.CanvasId,
        'Canvas owner ID': canvas.UserId,
        'Canvas owner team ID': canvas.User.TeamId,
        'Comment ID': annotation.id
    });
}

/**
 * A comment is assigned to/unassigned a user
 *
 * @export
 * @param {*} annotation
 * @param {*} canvas
 */
export function assignComment(userId, annotation, canvas) {
    const assignCommentData = {
        'Comment creator ID': annotation.UserId,
        'Canvas ID': annotation.CanvasId,
        'Canvas owner ID': canvas.UserId,
        'Canvas owner team ID': canvas.User.TeamId,
        'Comment ID': annotation.id,
        'New assignee': userId || 'Unassigned',
        'Previous assignee': annotation.assignedUserId || 'Unassigned'
    };

    mixpanel.track('Comment assigned', assignCommentData);
    if (window._cio) {
        window._cio.track('Comment assigned', assignCommentData);
    }
}

/**
 * A user deletes a comment
 *
 * @export
 * @param {*} annotation
 * @param {*} canvas
 */
export function deleteComment(annotation, canvas) {
    mixpanel.track('Comment deleted', {
        'Canvas ID': annotation.CanvasId,
        'Canvas owner ID': canvas.UserId,
        'Canvas owner team ID': canvas.User.TeamId,
        'Comment ID': annotation.id
    });
}

/**
 * A user navigates between comments in sidebar
 *
 * @export
 * @param {*} canvas
 * @param {*} direction
 * @param {*} shortcutUsed
 */
export function navigateSidebarComment(canvas, direction, shortcutUsed = false) {
    mixpanel.track('Sidebar comment navigation', {
        'Canvas ID': canvas.id,
        'Canvas owner ID': canvas.UserId,
        'Canvas owner team ID': canvas.User.TeamId,
        'Comment navigation direction': direction,
        'Comment navigation shortcut used': shortcutUsed
    });
}

/**
 * A user searches a canvas using the sidebar search
 *
 * @export
 * @param {*} canvas
 * @param {*} query
 */
export function searchCanvasSidebar(canvas, query) {
    mixpanel.track('Canvas sidebar search', {
        'Canvas ID': canvas.id,
        'Canvas owner ID': canvas.UserId,
        'Canvas owner team ID': canvas.User.TeamId,
        'Search query': query
    });
}

/**
 * A user changes the sort order for comments in the sidebar
 *
 * @export
 * @param {*} canvas
 * @param {*} previousSort
 * @param {*} newSort
 */
export function changeCommentSortOrder(canvas, previousSort, newSort) {
    const changeCommentSortOrderData = {
        'Canvas ID': canvas.id,
        'Canvas owner ID': canvas.UserId,
        'Canvas owner team ID': canvas.User.TeamId,
        'Previous sort order': previousSort,
        'New sort order': newSort
    };

    mixpanel.track('Comment sort order changed', changeCommentSortOrderData);
    if (window._cio) {
        window._cio.track('Comment sort order changed', changeCommentSortOrderData);
    }
}

/**
 * A user sets a comment filter on a canvas
 *
 * @export
 * @param {*} canvas
 * @param {*} filterName
 * @param {*} filterValue
 */
export function setCommentFilter(canvas, filterName, filterValue) {
    const setCommentFilterData = {
        'Canvas ID': canvas.id,
        'Canvas owner ID': canvas.UserId,
        'Canvas owner team ID': canvas.User.TeamId,
        'Filter name': filterName,
        'Filter value': String(filterValue)
    };

    mixpanel.track('Comment filter set', setCommentFilterData);
    if (window._cio) {
        window._cio.track('Comment filter set', setCommentFilterData);
    }
}

/**
 * A user clears a comment filter on a canvas
 *
 * @export
 * @param {*} canvas
 * @param {*} filterName
 */
export function clearCommentFilter(canvas, filterName) {
    const clearCommentFilterData = {
        'Canvas ID': canvas.id,
        'Canvas owner ID': canvas.UserId,
        'Canvas owner team ID': canvas.User.TeamId,
        'Filter name': filterName
    };

    mixpanel.track('Comment filter cleared', clearCommentFilterData);
    if (window._cio) {
        window._cio.track('Comment filter cleared', clearCommentFilterData);
    }
}

/**
 * A user clicks the "Current page comments only" checkbox on a canvas
 * @export
 * @param {*} canvas
 */
export function toggleCurrentPageComments(canvas, toggleOn = true) {
    const toggleCurrentPageCommentsData = {
        'Canvas ID': canvas?.id,
        'Canvas owner ID': canvas?.UserId,
        'Canvas owner team ID': canvas?.User.TeamId,
        'New "Current page comments only" state': toggleOn ? 'on' : 'off',
        'Previous "Current page comments only" state': toggleOn ? 'off' : 'on'
    };

    mixpanel.track('Current page comments only toggle', toggleCurrentPageCommentsData);
    if (window._cio) {
        window._cio.track('Current page comments only toggle', toggleCurrentPageCommentsData);
    }
}

/**
 * A user replies to a comment on a canvas
 *
 * @export
 * @param {*} reply
 * @param {*} annotation
 * @param {*} canvas
 */
export function createReply(reply, annotation, canvas) {
    const createReplyData = {
        'Canvas ID': annotation.CanvasId,
        'Canvas owner ID': canvas.UserId,
        'Canvas owner team ID': canvas.User.TeamId,
        'Comment creator ID': annotation.UserId,
        'Comment ID': annotation.id,
        'Files attached num': (reply.FileAttachments || []).length,
        'Reply ID': reply.id,
        'Users mentioned IDs': parseMentions(reply.comment).map((user) => user.id),
        'Reply visibility': reply.isPrivate ? 'team' : 'everyone'
    };

    mixpanel.track('Reply created', createReplyData);
    if (window._cio) {
        window._cio.track('Reply created', createReplyData);
    }
}

/**
 * A user edits a reply
 *
 * @export
 * @param {*} reply
 * @param {*} annotation
 * @param {*} canvas
 */
export function editReply(reply, annotation, canvas) {
    mixpanel.track('Reply edited', {
        'Canvas ID': annotation.CanvasId,
        'Canvas owner ID': canvas.UserId,
        'Canvas owner team ID': canvas.User.TeamId,
        'Comment ID': annotation.id,
        'Reply ID': reply.id
    });
}

/**
 * A user deletes a reply
 *
 * @export
 * @param {*} reply
 * @param {*} annotation
 * @param {*} canvas
 */
export function deleteReply(reply, annotation, canvas) {
    mixpanel.track('Reply deleted', {
        'Canvas ID': annotation.CanvasId,
        'Canvas owner ID': canvas.UserId,
        'Canvas owner team ID': canvas.User.TeamId,
        'Comment ID': annotation.id,
        'Reply ID': reply.id
    });
}

/**
 * Canvas image added
 *
 * @export
 * @param {*} canvas
 */
export function addCanvasAsset(canvas) {
    mixpanel.track('Canvas image added', {
        'Canvas ID': canvas.id,
        'Canvas owner ID': canvas.UserId,
        'Canvas owner team ID': canvas.User.TeamId
    });
}

/**
 * An image or PDF on a design canvas was replaced
 *
 * @export
 * @param {*} canvas
 * @param {*} fileType
 */
export function replaceCanvasAsset(canvas, fileType = 'image') {
    const replaceCanvasAssetData = {
        'Canvas ID': canvas.id,
        'Canvas owner ID': canvas.UserId,
        'Canvas owner team ID': canvas.User.TeamId,
        'File type': fileType
    };

    mixpanel.track('Canvas asset replaced', replaceCanvasAssetData);
    if (window._cio) {
        window._cio.track('Canvas asset replaced', replaceCanvasAssetData);
    }
}

/**
 * Image or PDF deleted on a design canvas
 *
 * @export
 * @param {*} canvas
 * @param {string} [fileType='image']
 */
export function deleteCanvasAsset(canvas, fileType = 'image') {
    mixpanel.track('Canvas asset deleted', {
        'Canvas ID': canvas.id,
        'Canvas owner ID': canvas.UserId,
        'Canvas owner team ID': canvas.User.TeamId,
        'File type': fileType
    });
}

/**
 * Canvas image selected
 *
 * @export
 * @param {*} canvas
 * @param {*} canvasAsset
 * @param {*} previousCanvasAsset
 * @param {*} canvasAssetPageIndex
 * @param {*} previousCanvasAssetPageIndex
 */
export function selectCanvasAsset(
    canvas,
    canvasAsset,
    previousCanvasAsset,
    canvasAssetPageIndex,
    previousCanvasAssetPageIndex,
    shortcutUsed = false
) {
    mixpanel.track('Canvas image selected', {
        'Canvas ID': canvas.id,
        'Canvas owner ID': canvas.UserId,
        'Canvas owner team ID': canvas.User.TeamId,
        'File type': canvasAsset.type,
        'Image navigation shortcut used': shortcutUsed,
        'New image selected': canvasAsset.position,
        'New page selected': canvasAssetPageIndex !== null ? canvasAssetPageIndex + 1 : undefined,
        'Previous image selected': previousCanvasAsset?.position,
        'Previous page selected': previousCanvasAssetPageIndex !== null ? previousCanvasAssetPageIndex + 1 : undefined
    });
}

/**
 * Canvas image rearranged
 *
 * @export
 * @param {*} canvas
 * @param {*} previousImagePosition
 * @param {*} newImagePosition
 */
export function rearrangeCanvasAsset(canvas, previousImagePosition, newImagePosition) {
    mixpanel.track('Canvas image rearranged', {
        'Canvas ID': canvas.id,
        'Canvas owner ID': canvas.UserId,
        'Canvas owner team ID': canvas.User.TeamId,
        'New image position': newImagePosition,
        'Previous image position': previousImagePosition
    });
}

/**
 * Canvas revision added
 *
 * @export
 * @param {*} canvas
 */
export function addCanvasRevision(canvas) {
    const addedCanvasRevisionData = {
        'Canvas ID': canvas.id,
        'Canvas owner ID': canvas.UserId,
        'Canvas owner team ID': canvas.User.TeamId,
        'Canvas type': canvas.type
    };

    mixpanel.track('Canvas revision added', addedCanvasRevisionData);
    if (window._cio) {
        window._cio.track('Canvas revision added', addedCanvasRevisionData);
    }
}

/**
 * Canvas revision selected
 *
 * @export
 * @param {*} canvas
 * @param {*} canvasRevision
 * @param {*} previousCanvasRevision
 */
export function selectCanvasRevision(canvas, canvasRevision, previousCanvasRevision) {
    mixpanel.track('Canvas revision selected', {
        'Canvas ID': canvas.id,
        'Canvas owner ID': canvas.UserId,
        'Canvas owner team ID': canvas.User.TeamId,
        'Canvas type': canvas.type,
        'New revision selected': canvasRevision.number,
        'Previous revision selected': previousCanvasRevision?.number
    });
}

/**
 * Canvas review added
 *
 * @export
 * @param {*} canvas
 * @param {*} canvasReview
 */
export function addCanvasReview(canvas, canvasReview) {
    mixpanel.track('Canvas review added', {
        'Canvas ID': canvas.id,
        'Canvas owner ID': canvas.UserId,
        'Canvas owner team ID': canvas.User.TeamId,
        'Canvas revision ID': canvasReview.CanvasRevisionId,
        'Canvas type': canvas.type,
        'Review status': canvasReview.status,
        'Reviewer ID': canvasReview.UserId
    });
}

/**
 * A user creates a label
 *
 * @export
 * @param {*} label
 */
export function createLabel(label) {
    mixpanel.track('Label created', {
        'Label colour': label.color,
        'Label name': label.name
    });
}

/**
 * A user adds a label to a comment
 *
 * @export
 * @param {*} label
 * @param {*} annotation
 * @param {*} canvas
 */
export function addLabel(label, annotation, canvas) {
    mixpanel.track('Label added', {
        'Canvas ID': canvas.id,
        'Canvas owner ID': canvas.UserId,
        'Canvas owner team ID': canvas.User.TeamId,
        'Comment creator ID': annotation.UserId,
        'Comment ID': annotation.id,
        'Label colour': label.color,
        'Label name': label.name
    });
}

/**
 * A user removes a label from a comment
 *
 * @export
 * @param {*} label
 * @param {*} annotation
 * @param {*} canvas
 */
export function removeLabel(label, annotation, canvas) {
    mixpanel.track('Label removed', {
        'Canvas ID': canvas.id,
        'Canvas owner ID': canvas.UserId,
        'Canvas owner team ID': canvas.User.TeamId,
        'Comment creator ID': annotation.UserId,
        'Comment ID': annotation.id,
        'Label colour': label.color,
        'Label name': label.name
    });
}

/**
 * A user sets a feedback deadline on a canvas
 *
 * @export
 * @param {*} canvas
 * @param {string} [type='pause']
 * @param {*} date
 * @param {*} location
 */
export function setFeedbackDeadline(canvas, type = 'set', date, location) {
    const deadlineLength = date ? moment(date).diff(moment(), 'days') : undefined;
    const setFeedbackDeadlineData = {
        'Canvas ID': canvas.id,
        'Canvas owner ID': canvas.UserId,
        'Canvas owner team ID': canvas.User.TeamId,
        'Deadline action type': type,
        'Deadline date': date ? date.toISOString() : undefined,
        'Deadline length': deadlineLength,
        'Deadline location': location
    };

    mixpanel.track('Feedback deadline set', setFeedbackDeadlineData);
    if (window._cio) {
        window._cio.track('Feedback deadline set', setFeedbackDeadlineData);
    }
}

/**
 * A user sets a feedback deadline reminder on a canvas
 *
 * @export
 * @param {*} canvas
 * @param {*} feedbackDeadlineReminder
 */
export function setFeedbackDeadlineReminder(canvas, feedbackDeadlineReminder) {
    mixpanel.track('Feedback deadline reminder set', {
        'Canvas ID': canvas.id,
        'Canvas owner ID': canvas.UserId,
        'Canvas owner team ID': canvas.User.TeamId,
        'Canvas deadline reminder ID': feedbackDeadlineReminder.id,
        'Canvas type': canvas.type,
        'Reminder date': feedbackDeadlineReminder.reminderDate,
        'Recipient ID': feedbackDeadlineReminder.recipientUserId,
        'User ID': feedbackDeadlineReminder.UserId
    });
}

/**
 * Feedback deadline reminder deleted
 *
 * @export
 * @param {*} canvas
 * @param {*} feedbackDeadlineReminder
 * @param {*} source // 'feedback-deadline-deleted' | 'feedback-deadline-updated' | 'single'
 */
export function deleteFeedbackDeadlineReminder(canvas, feedbackDeadlineReminder, source, deletionUserId) {
    mixpanel.track('Feedback deadline reminder deleted', {
        'Canvas ID': canvas.id,
        'Canvas owner ID': canvas.UserId,
        'Canvas owner team ID': canvas.User.TeamId,
        'Canvas deadline reminder ID': feedbackDeadlineReminder.id,
        'Canvas type': canvas.type,
        'Reminder date': feedbackDeadlineReminder.reminderDate,
        'Recipient ID': feedbackDeadlineReminder.recipientUserId,
        'User ID': feedbackDeadlineReminder.UserId,
        Status: feedbackDeadlineReminder.status,
        'Deletion source': source,
        'Deletion User ID': deletionUserId
    });
}

/**
 * A user pauses commenting on a canvas
 *
 * @export
 * @param {*} canvas
 * @param {string} [type='pause']
 * @param {*} location
 */
export function pauseCommenting(canvas, type = 'pause', location) {
    const pauseCommentingData = {
        'Canvas ID': canvas.id,
        'Canvas owner ID': canvas.UserId,
        'Canvas owner team ID': canvas.User.TeamId,
        'Pause location': location,
        'Pause type': type
    };

    mixpanel.track('Commenting paused', pauseCommentingData);
    if (window._cio) {
        window._cio.track('Commenting paused', pauseCommentingData);
    }
}

/**
 * A user clicks on the time left / expired tag on a canvas
 *
 * @export
 * @param {*} canvas
 * @param {*} location
 */
export function clickCanvasTimeTag(canvas, location) {
    mixpanel.track('Time tag canvas clicked', {
        'Canvas ID': canvas.id,
        'Canvas owner ID': canvas.UserId,
        'Time tag location': location
    });
}

/**
 * A user batch deletes their comments
 *
 * @export
 * @param {*} canvas
 * @param {*} deletedAnnotations
 */
export function commentsBatchDeleted(canvas, deletedAnnotations) {
    mixpanel.track('Comments batch deleted', {
        'Canvas ID': canvas.id,
        'Canvas owner ID': canvas.UserId,
        'Number of comments deleted': deletedAnnotations.length,
        'Comment IDs': deletedAnnotations.map((annotation) => annotation.id)
    });
}

/**
 * A user batch updates comments -
 * changing the status, assigning a user or updating comment visibility
 *
 * @export
 * @param {*} canvas
 * @param {*} actionedAnnotations
 * @param {*} actions
 */
export function commentsBatchActioned(canvas, actionedAnnotations, action) {
    mixpanel.track('Comments batch actioned', {
        'Canvas ID': canvas.id,
        'Canvas owner ID': canvas.UserId,
        'Number of comments actioned': actionedAnnotations.length,
        'Type of action': action,
        'Comment IDs': actionedAnnotations.map((annotation) => annotation.id)
    });
}

// -------------- DASHBOARD & FOLDER EVENTS -------------- //

/**
 * A user searches for canvases on the dashboard, or within a project or archived canvases
 *
 * @export
 * @param {*} query
 */
export function searchDashboardCanvases(query) {
    mixpanel.track('Dashboard search', {
        'Search query': query
    });
}

/**
 * A user searches for folders on the dashboard
 *
 * @export
 * @param {*} query
 */
export function searchDashboardFolders(query) {
    mixpanel.track('Dashboard folders search', {
        'Search query': query
    });
}

/**
 * A user changes the sort order for canvases on the dashboard, in a folder or archived
 *
 * @export
 * @param {*} previousSort
 * @param {*} newSort
 */
export function changeCanvasSortOrder(previousSort, newSort) {
    const changeCanvasSortOrderData = {
        'Previous sort order': previousSort,
        'New sort order': newSort
    };

    mixpanel.track('Canvas sort order changed', changeCanvasSortOrderData);
    if (window._cio) {
        window._cio.track('Canvas sort order changed', changeCanvasSortOrderData);
    }
}

/**
 * A user sets a canvas filter on the dashboard
 *
 * @export
 * @param {*} filterName
 * @param {*} filterValue
 */
export function setCanvasFilter(filterName, filterValue) {
    const setCanvasFilterData = {
        'Filter name': filterName,
        'Filter value': String(filterValue)
    };

    mixpanel.track('Canvas filter set', setCanvasFilterData);
    if (window._cio) {
        window._cio.track('Canvas filter set', setCanvasFilterData);
    }
}

/**
 * A user clears a canvas filter on the dashboard
 *
 * @export
 * @param {*} filterName
 */
export function clearCanvasFilter(filterName) {
    const clearCanvasFilterData = {
        'Filter name': filterName
    };

    mixpanel.track('Comment filter cleared', clearCanvasFilterData);
    if (window._cio) {
        window._cio.track('Comment filter cleared', clearCanvasFilterData);
    }
}

/**
 * A user creates a folder (or sub-folder)
 *
 * @export
 * @param {*} folder
 */
export function createFolder(folder) {
    mixpanel.track('Folder created', {
        'Folder ID': folder.id,
        'Folder name': folder.name,
        'Parent folder ID': folder.parentFolderId || null
    });
}

/**
 * A user renames a folder (or sub-folder)
 *
 * @export
 * @param {*} folder
 * @param {*} newFolderName
 * @param {*} previousFolderName
 * @param {*} method // form submit or editable field
 */
export function renameFolder(folder, newFolderName, previousFolderName, method = 'form submit') {
    mixpanel.track('Folder renamed', {
        'Folder ID': folder.id,
        'Folder name': newFolderName,
        'Folder owner ID': folder.UserId,
        'Previous folder name': previousFolderName,
        'Parent folder ID': folder.parentFolderId || null,
        Method: method
    });
}

/**
 * A user updates a folder (or sub-folder) description
 *
 * @export
 * @param {*} folder
 * @param {*} newFolderDescription
 * @param {*} previousFolderDescription
 * @param {*} method // form submit or editable field
 */
export function updateFolderDescription(
    folder,
    newFolderDescription,
    previousFolderDescription,
    method = 'form submit'
) {
    mixpanel.track('Folder description updated', {
        'Folder description': newFolderDescription,
        'Folder ID': folder.id,
        'Folder owner ID': folder.UserId,
        'Folder owner team ID': folder.User.TeamId,
        'Previous folder description': previousFolderDescription,
        'Parent folder ID': folder.parentFolderId || null,
        Method: method
    });
}

/**
 * A user changes the folder (or sub-folder) visibility
 *
 * @export
 * @param {*} folder
 * @param {*} newFolderVisibility
 * @param {*} previousFolderDescription
 */
export function changeFolderVisibility(folder, newFolderVisibility, previousFolderDescription) {
    mixpanel.track('Folder visibility changed', {
        'Folder ID': folder.id,
        'Folder owner ID': folder.UserId,
        'Folder owner team ID': folder.User.TeamId,
        'New folder visibility': newFolderVisibility,
        'Previous folder visibility': previousFolderDescription,
        'Parent folder ID': folder.parentFolderId || null
    });
}

/**
 * A user shares a folder (or sub-folder) using email share or link copy
 *
 * @export
 * @param {*} folder
 * @param {string} [method='email']
 * @param {*} numEmails
 * @param {*} customMessage
 * @param {*} location
 */
export function shareFolder(folder, method = 'email', numEmails, customMessage) {
    mixpanel.track('Folder shared', {
        'Custom message': customMessage,
        'Folder ID': folder.id,
        'Folder owner ID': folder.UserId,
        'Folder owner team ID': folder.User.TeamId,
        'Parent folder ID': folder.parentFolderId || null,
        'Number of emails shared with': numEmails,
        'Share method': method
    });
}

/**
 * A user deletes a folder (or sub-folder)
 *
 * @export
 * @param {*} folder
 * @param {*} numCanvasesInFolder
 */
export function deleteFolder(folder, numCanvasesInFolder) {
    mixpanel.track('Folder deleted', {
        'Canvases in folder': numCanvasesInFolder,
        'Folder ID': folder.id,
        'Folder owner ID': folder.UserId,
        'Parent folder ID': folder.parentFolderId || null
    });
}

/**
 * A user adds a canvas to a folder (or sub-folder)
 *
 * @export
 * @param {*} canvas
 * @param {*} folder
 * @param {*} previousFolder
 */
export function addCanvasToFolder(canvas, folder, previousFolder) {
    mixpanel.track('Canvas added to folder', {
        'Canvas ID': canvas.id,
        'Canvas owner ID': canvas.UserId,
        'New canvas folder ID': folder.id,
        'New folder owner ID': folder.UserId,
        'New folder parent folder ID': folder.parentFolderId || null,
        'Previous canvas folder ID': previousFolder?.id,
        'Previous folder owner ID': previousFolder?.UserId,
        'Previous folder parent folder ID': previousFolder?.parentFolderId || null
    });
}

/**
 * A user removes a canvas from a folder (or sub-folder)
 *
 * @export
 * @param {*} canvas
 * @param {*} folder
 * @param {*} previousFolder
 */
export function removeCanvasFromFolder(canvas, folder, newFolder) {
    mixpanel.track('Canvas removed from folder', {
        'Canvas ID': canvas.id,
        'Canvas owner ID': canvas.UserId,
        'New canvas folder ID': newFolder?.id,
        'New folder owner ID': newFolder?.UserId,
        'New folder parent folder ID': newFolder?.parentFolderId || null,
        'Previous canvas folder ID': folder.id,
        'Previous folder owner ID': folder.UserId,
        'Previous folder parent folder ID': folder?.parentFolderId || null
    });
}

// -------------- EXPORT EVENTS -------------- //

/**
 * A user sets up an export integration
 *
 * @export
 * @param {*} canvas
 * @param {*} integrationType
 */
export function setupExport(canvas, integrationType) {
    const setupExportData = {
        'Canvas ID': canvas?.id,
        'Canvas owner ID': canvas?.UserId,
        'Canvas owner team ID': canvas?.User.TeamId,
        'Integration type': integrationType
    };

    mixpanel.track('Export setup', setupExportData);
    if (window._cio) {
        window._cio.track('Export setup', setupExportData);
    }
}

/**
 * A user exports comments
 *
 * @export
 * @param {*} canvas
 * @param {*} integrationType
 * @param {*} numComments
 */
export function exportComments(canvas, integrationType, numComments) {
    mixpanel.track('Comments exported', {
        'Canvas ID': canvas?.id,
        'Canvas owner ID': canvas?.UserId,
        'Canvas owner team ID': canvas?.User.TeamId,
        'Integration type': integrationType,
        'Number of comments exported': numComments
    });
}

/**
 * A user disconnects an export integration
 *
 * @export
 * @param {*} canvas
 * @param {*} integrationType
 */
export function disconnectExport(canvas, integrationType) {
    mixpanel.track('Export disconnected', {
        'Canvas ID': canvas?.id,
        'Canvas owner ID': canvas?.UserId,
        'Canvas owner team ID': canvas?.User.TeamId,
        'Integration type': integrationType
    });
}
