import { MODAL_CANVAS_SETTINGS_HIDE, MODAL_CANVAS_SETTINGS_SHOW } from './types';

const initialState = {
    canvasSettingsModalCanvasId: null
};

const modals = (state = initialState, action) => {
    switch (action.type) {
        case MODAL_CANVAS_SETTINGS_HIDE: {
            return { ...state, canvasSettingsModalCanvasId: null };
        }
        case MODAL_CANVAS_SETTINGS_SHOW: {
            return { ...state, canvasSettingsModalCanvasId: action.payload.canvasId };
        }
        default:
            return state;
    }
};

export default modals;
