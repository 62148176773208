import moment from 'moment';
import config from './config';

const flags = {};

// -------------- USER/CUSTOMER FLAGS -------------- //

/**
 * Determines whether a customer is active
 *
 * @param customer
 * @returns {boolean}
 */
flags.customerIsActive = (customer) => {
    if (!customer) {
        return true;
    }

    // Extends active period for paying customers
    // in the case that their payment didn't go through or something
    if (customer.status === 'active' || customer.status === 'past_due') {
        return moment(customer.periodEnd).add(7, 'days') >= moment();
    }

    // A bit of leeway for trials (to give time for payment to fail and switch to cancelled)
    if (customer.status === 'trialing') {
        return moment(customer.periodEnd).add(2, 'hours') >= moment();
    }

    return moment(customer.periodEnd) >= moment();
};

/**
 * Determines whether a user is active, based on the associated customer object
 *
 * @param user
 * @returns {boolean}
 */
flags.userIsActive = (user) => {
    if (!user) {
        return true;
    }

    return flags.customerIsActive(user.Customer);
};

/**
 * Determines whether a user is a guest or not
 *
 * @param user
 * @returns {boolean}
 */
flags.userIsGuest = (user) => {
    if (!user) {
        return true;
    }

    return user.isGuest;
};

/**
 * Determines whether a user is trialing, based on the associated customer object
 *
 * @param user
 * @returns {boolean}
 */
flags.userIsTrialing = (user) => user?.Customer?.status === 'trialing' && (!user.Team || flags.userIsTeamOwner(user));

/**
 * Determines whether user is on free plan
 *
 * @param user
 * @returns {boolean}
 */
flags.userIsOnFreePlan = (user) => user?.Customer.planId === 'free0';

/**
 * Determines whether user is on the Solo plan
 *
 * @param user
 * @returns {boolean}
 */
flags.userIsOnSoloPlan = (user) =>
    Boolean(user?.Customer) &&
    [
        'individual19',
        'individual19yearly',
        'individual29',
        'individual29yearly',
        'individual35',
        'individual35yearly'
    ].includes(user.Customer.planId);

/**
 * Determines whether a user is the team owner
 *
 * @param user
 * @returns {boolean}
 */
flags.userIsTeamOwner = (user) => {
    if (!user) {
        return false;
    }

    const team = user.Team;

    if (!team) {
        return false;
    }

    return user.id === team.ownerUserId;
};

/**
 * Determines whether a user is an admin of their team (inclusive of team owner)
 *
 * @param user
 * @returns {boolean}
 */
flags.userIsTeamAdmin = (user) => {
    if (!user) {
        return false;
    }

    const team = user.Team;

    if (!team) {
        return false;
    }

    return user.id === team.ownerUserId || user.role === 'admin';
};

/**
 * Returns whether user can edit a canvas
 * They can do so if they are the canvas owner, a team admin or the team owner
 *
 * @param user
 * @param canvas
 * @returns {boolean}
 */
flags.userCanEditCanvas = function (user, canvas) {
    if (!user || !canvas) {
        return false;
    }

    return canvas.User.TeamId === user.TeamId && (user.id === canvas.UserId || flags.userIsTeamAdmin(user));
};

/**
 * Returns whether user has access to a canvas
 *
 * @param user
 * @param canvas
 * @returns {boolean}
 */
flags.userCanAccessCanvas = function (user, canvas) {
    if (!user || !canvas) {
        return false;
    }

    return (flags.userHasTeam(user) && canvas.User.TeamId === user.TeamId) || user.id === canvas.UserId;
};

/**
 * Returns whether user belongs to a team
 *
 * @param user
 * @returns {boolean}
 */
flags.userHasTeam = (user) => Boolean(user?.Team);

/**
 * Determines whether user is on a plan that has a limit on the number of premium (non-expiring) canvases
 *
 * @param user
 * @returns {boolean}
 */
flags.userHasPremiumCanvasesLimit = (user) => flags.userIsOnSoloPlan(user);

/**
 * Determines whether a user is an internal tester
 *
 * @param user
 * @returns {boolean}
 */
flags.userIsInternalTester = (user) => {
    if (!user) {
        return false;
    }

    if (config.env === 'development') {
        return true;
    }

    const testers = [
        'valentin@usepastel.com',
        'aloke@usepastel.com',
        'haneef@usepastel.com',
        'haneef.ghanim@gmail.com',
        'djalneo@gmail.com',
        'valentin.s41@gmail.com',
        'annie@usepastel.com',
        'omar@usepastel.com',
        'aloke@chachu.co'
    ];

    return testers.includes(user.email) && !user.isGuest;
};

/**
 * Determines whether user has the ability to enable SSO login for their plan
 *
 * @param user
 * @returns {boolean}
 */
flags.userCanEnableSSO = (user) => {
    if (!user || !user.Team || !user.Team.Customer) {
        return false;
    }

    if (
        ![
            'enterprise35peruser',
            'enterprise35peruseryearly',
            'enterprise45peruser',
            'enterprise45peruseryearly'
        ].includes(user.Team.Customer.planId)
    ) {
        return false;
    }

    return flags.userIsTeamOwner(user);
};

/**
 * Determines if a given user has SSO enabled
 *
 * @param user
 * @returns {boolean}
 */
flags.userHasSSOEnabled = (user) => {
    if (!user || !user.Team || !user.Team.Customer || !user.Team.SSOConfig) {
        return false;
    }

    if (
        ![
            'enterprise35peruser',
            'enterprise35peruseryearly',
            'enterprise45peruser',
            'enterprise45peruseryearly'
        ].includes(user.Team.Customer.planId)
    ) {
        return false;
    }

    return user.Team.SSOConfig.enabled;
};

/**
 * Determines whether user can view their API token
 *
 * @param user
 * @returns {boolean}
 */
flags.userHasAPITokenEnabled = (user) => {
    if (!user || !user.Customer) {
        return false;
    }

    return Boolean(user && user.Team) && !['team49', 'team49yearly'].includes(user.Customer.planId);
};

/**
 * Determines whether user has email review & approval feature enabled
 *
 * @param user
 * @returns {boolean}
 */
flags.userHasEmailReviewEnabled = (user) => {
    if (!user || !user.Customer || !user.Customer.betaFeaturesEnabled || !user.Team) {
        return false;
    }

    return !!user.Customer.betaFeaturesEnabled.emailReview;
};

/**
 * Determines whether user can see billing section
 *
 * @param user
 * @returns {boolean}
 */
flags.userCanViewBilling = (user) => {
    if (!user || user.isGuest) {
        return false;
    }

    if (!user.Team) {
        return true;
    }

    return flags.userIsTeamOwner(user);
};

/**
 * Determines whether user can view labels
 *
 * @param canvas
 * @returns {boolean}
 */
flags.userCanViewLabels = (canvas) => {
    if (!canvas || !canvas.Customer) {
        return false;
    }

    return Boolean(canvas.User && canvas.User.Team) && !['team49', 'team49yearly'].includes(canvas.Customer.planId);
};

/**
 * Determines whether user can add and remove labels from an annotation
 *
 * @param user
 * @param canvas
 * @param annotation (optional)
 * @returns {boolean}
 */
flags.userCanAddAndRemoveLabels = (user, canvas, annotation) => {
    if (!user || !canvas || !flags.userCanViewLabels(canvas)) {
        return false;
    }

    return user.TeamId === canvas.User.TeamId || (annotation && annotation.UserId === user.id);
};

/**
 * Determines whether user can create/edit new labels for a team
 *
 * @param user
 * @param canvas
 * @returns {boolean}
 */
flags.userCanCreateAndEditLabels = (user, canvas) => {
    if (!user || !canvas || !flags.userCanViewLabels(canvas)) {
        return false;
    }

    return user.TeamId === canvas.User.TeamId;
};

/**
 * Determines whether user can delete another user's comments
 *
 * @param user
 * @param canvas
 * @returns {boolean}
 */
flags.userCanDeleteOtherUsersComments = (user, canvas) => {
    if (!user || !flags.userIsTeamAdmin(user)) {
        return false;
    }

    return user.TeamId === canvas.User.TeamId;
};

/**
 * Determines whether user can assign comments to other users
 * Enabled for all team plans except for Boutique.
 *
 * @param user
 * @param canvas
 * @returns {boolean}
 */
flags.userCanAssignComments = (user, canvas) => {
    if (!user || !user.Customer || !canvas) {
        return false;
    }

    return (
        user.TeamId &&
        canvas.User?.TeamId &&
        !['team49', 'team49yearly'].includes(user.Customer.planId) &&
        user.TeamId === canvas.User.TeamId
    );
};

/**
 * Determines whether user can leave private comments on a canvas
 * Enabled for all team plans except for Boutique
 *
 * @param user
 * @param canvas
 * @returns {boolean}
 */
flags.userHasPrivateCommentsEnabled = (user, canvas) => {
    if (!user || !canvas) {
        return false;
    }

    const team = user.Team;

    if (!team || !team.Customer || ['team49', 'team49yearly'].includes(team.Customer.planId)) {
        return false;
    }

    return canvas.User && canvas.User.TeamId === team.id;
};

/**
 * Determines whether user can attach files on a canvas
 *
 * @param canvas
 * @returns {boolean}
 */
flags.userCanAttachFiles = (canvas) => {
    if (!canvas || !canvas.Customer) {
        return false;
    }

    return canvas.Customer.planId !== 'free0';
};

/**
 * Determines whether user can pause/unpause a given canvas/canvas
 * Pausing is only enabled on team plans
 *
 * @param user
 * @param canvas
 * @returns {boolean}
 */
flags.userCanPauseCanvas = (user, canvas) => {
    if (!user?.Customer || !canvas) {
        return false;
    }

    return (
        Boolean(user?.Team) &&
        !['team49', 'team49yearly'].includes(user.Customer.planId) &&
        (user.id === canvas.UserId || (flags.userIsTeamAdmin(user) && canvas.User.TeamId === user.TeamId))
    );
};

/**
 * Determines whether @mentions are enabled for a user
 *
 * @param user
 * @param canvas
 * @returns {boolean}
 */
flags.userCanAtMentionOthers = (canvas) => {
    if (!canvas || !canvas.Customer || !canvas.User) {
        return false;
    }

    return Boolean(canvas.User && canvas.User.Team) && !['team49', 'team49yearly'].includes(canvas.Customer.planId);
};

/**
 * Determines whether all user has comment export options enabled
 *
 * @param user
 * @returns {boolean}
 */
flags.userHasAllExportOptionsEnabled = (user) => {
    if (!user || !user.Customer) {
        return false;
    }

    return Boolean(user && user.Team) && !['team49', 'team49yearly'].includes(user.Customer.planId);
};

/**
 * Determines whether user can see folders on the dashboard
 *
 * @param user
 * @returns {boolean}
 */
flags.userHasFoldersLayoutEnabled = (user) => {
    if (!user || !user.Customer) {
        return false;
    }

    return Boolean(user && user.Team) && !['team49', 'team49yearly'].includes(user.Customer.planId);
};

/**
 * Determines whether user can edit folder
 *
 * @param user
 * @param folder
 * @returns {boolean}
 */
flags.userCanEditFolder = (user, folder) => {
    if (!user?.Team || !folder) {
        return false;
    }

    return flags.userHasTeam(user) && folder.User?.TeamId === user.TeamId;
};

// -------------- CANVAS FLAGS -------------- //

/**
 * Determines whether assigning comments are enabled on a canvas
 *
 * @param canvas
 * @returns {boolean}
 */
flags.canvasHasAssigningCommentsEnabled = (canvas) => {
    if (!canvas || !canvas.Customer) {
        return false;
    }

    return Boolean(canvas.User && canvas.User.Team) && !['team49', 'team49yearly'].includes(canvas.Customer.planId);
};

/**
 * Determines whether a canvas can be paused
 * Useful in the edge case where a customer has an existing readOnlyDate set
 * but then downgrades to a plan without that feature
 *
 * @param canvas
 * @returns {boolean}
 */
flags.canvasHasPausingEnabled = (canvas) => {
    if (!canvas) {
        return false;
    }

    if (!canvas.User.TeamId || ['team49', 'team49yearly'].includes(canvas.Customer.planId)) {
        return false;
    }

    return true;
};

/**
 * Determines whether a canvas is paused
 *
 * @param canvas
 * @returns {boolean}
 */
flags.canvasIsPaused = (canvas) =>
    !!(flags.canvasHasPausingEnabled(canvas) && canvas.readOnlyDate && moment(canvas.readOnlyDate) <= moment());

/**
 * Determines whether a canvas is expired
 *
 * @param canvas
 * @returns {boolean}
 */
flags.canvasIsExpired = (canvas) => canvas?.expireDate && moment(canvas.expireDate) <= moment();

/**
 * Determines whether a canvas is currently read-only
 *
 * @param canvas
 * @param user
 * @returns {boolean}
 */
flags.canvasIsReadOnly = (canvas, user) => {
    if (!canvas) {
        return false;
    }
    // Owner of canvas isn't active
    if (!flags.customerIsActive(canvas.Customer)) {
        return true;
    }

    // User is on a solo plan and canvas is premium or not expired
    if (
        [
            'individual19',
            'individual19yearly',
            'individual29',
            'individual29yearly',
            'individual35',
            'individual35yearly'
        ].includes(canvas.Customer.planId) &&
        (canvas.isPremium || !flags.canvasIsExpired(canvas))
    ) {
        return false;
    }

    // Read-only or expiry date is in the past and if user is guest.
    return (flags.canvasIsPaused(canvas) && flags.userIsGuest(user)) || flags.canvasIsExpired(canvas);
};

/**
 * Determines whether a canvas has a feedback deadline
 *
 * @param canvas
 * @returns {boolean}
 */
flags.canvasHasFeedbackDeadline = (canvas) =>
    flags.canvasHasPausingEnabled(canvas) && canvas.readOnlyDate && moment(canvas.readOnlyDate) > moment();

export default flags;
